exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-professional-services-tsx": () => import("./../../../src/pages/professional-services.tsx" /* webpackChunkName: "component---src-pages-professional-services-tsx" */),
  "component---src-pages-refund-policy-tsx": () => import("./../../../src/pages/refund-policy.tsx" /* webpackChunkName: "component---src-pages-refund-policy-tsx" */),
  "component---src-pages-ride-tsx": () => import("./../../../src/pages/ride.tsx" /* webpackChunkName: "component---src-pages-ride-tsx" */),
  "component---src-pages-self-service-tools-tsx": () => import("./../../../src/pages/self-service-tools.tsx" /* webpackChunkName: "component---src-pages-self-service-tools-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-utility-tsx": () => import("./../../../src/pages/utility.tsx" /* webpackChunkName: "component---src-pages-utility-tsx" */)
}

